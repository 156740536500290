import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { LoginComponent } from './core/pages/login/login.component';
import { SignUpComponent } from './core/pages/sign-up/sign-up.component';
import { NavbarComponent } from './core/components/navbar/navbar.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { AppRoutingModule } from './app.routing.module';


import { HttpClientModule } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy} from '@angular/common';

import { AllReservationsComponent } from './core/pages/calendar_reservations/calendar_reservations.component';
import { NFCComponent } from './core/pages/nfc/nfc.component';

import { OrderModule } from 'ngx-order-pipe';
import { NgxPaginationModule} from 'ngx-pagination';
import { UserService } from './core/services/user.service';
import { FilterPipeModule } from 'ngx-filter-pipe';

import { SearchFilterPipe } from './shared/pipes/search-filter.pipe';
import { HighlightSearch } from './shared/pipes/search-highlight.pipe';
import { ModalViewReservationComponent } from './shared/components/modal_edit_reservation/modal_edit_reservation.component';

//Check in package.jon
/*    "@angular/animations": "~7.1.0",
      "@angular/material": "~7.1.0",

Run: npm install
*/
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule, MatSelectModule } from '@angular/material';

/*export const MY_FORMATS = {//Material Datapicker Optional
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};*/

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignUpComponent,
    NavbarComponent,
    FooterComponent,
    AllReservationsComponent,
    NFCComponent,
    SearchFilterPipe,
    HighlightSearch,
    ModalViewReservationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    OrderModule,
    NgxPaginationModule,
    FilterPipeModule,
    MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, BrowserAnimationsModule, MatCardModule, MatDatepickerModule, MatSelectModule, MatFormFieldModule, MatInputModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy,},UserService,
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'}, //Material Datapicker Optional
    //{provide: MAT_DATE_FORMATS, useValue: MY_FORMATS} //Material Datapicker Optional
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
