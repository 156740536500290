import { Injectable } from '@angular/core';
import { User } from '../models/user.model';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalConstants } from 'src/app/shared/constants/global-constants';
import { BehaviorSubject, throwError } from 'rxjs';

import { catchError, map, tap } from 'rxjs/operators';

import * as cryptoJS from "crypto-js";

@Injectable()
export class UserService {

  private isUserLoggedIn;
  public usserLogged:User;
  config;

  private userDataSource = new BehaviorSubject({email : '', password : ''});
  currentUserData = this.userDataSource.asObservable();

  constructor(public http: HttpClient) { 
  	this.isUserLoggedIn = false;
    
    this.config = {headers : {
      "Content-Type": "application/json; charset = utf-8;"
    }}; 
  }

  setUserLoggedIn(user) {
    this.isUserLoggedIn = true;
    this.usserLogged = user.user;
    localStorage.setItem('currentUser', JSON.stringify(user)); 
  }

  getUserLoggedIn() {
  	return JSON.parse(localStorage.getItem('currentUser'));
  }

  login(user){
    var post= this.http.post(`${GlobalConstants.apiURL}login.php`, JSON.stringify(user ,this.config));
    return post;
  }

  logout(){

    localStorage.clear();

    var post = this.http.get(`${GlobalConstants.apiURL}logout.php`);
    return post
  }

  changeData(newUserData) {
    this.userDataSource.next(newUserData)
  }

  new_user(user){ 
    var post=this.http.post(`${GlobalConstants.apiURL}new_user.php`,JSON.stringify({user},this.config));
    return post
  }

  new_user_confirm(user){ 
    var post=this.http.post(`${GlobalConstants.apiURL}new_user_confirm.php`,JSON.stringify({user},this.config));
    return post
  }

  get_signup_data(data) {
  	//var post= this.http.post(`${GlobalConstants.apiURL}get_website_data.php`, JSON.stringify(this.config));
    //const httpResponse = this.http.get(`${GlobalConstants.apiURL}get_reservation.php`);
    const httpResponse = this.http.post(`${GlobalConstants.apiURL}get_signup_data.php`,JSON.stringify({data},this.config) ).pipe(
      /*tap(_ => 
        console.log('tap')
      ),*/
      map((res: any) => {

        let decrypted = cryptoJS.AES.decrypt(res, cryptoJS.enc.Utf8.parse(GlobalConstants.crypto.key), GlobalConstants.crypto.configuration).toString(cryptoJS.enc.Utf8);

        res= JSON.parse(decrypted);

        return res;
      }),
      catchError((res: HttpErrorResponse) => { // Si no responde
        /*console.log(JSON.stringify(res));
        this.router.navigate(['/']);*/

        return throwError(JSON.stringify(res));
      })
    );

    
    
    /*let encrypted = cryptoJS.AES.encrypt(this.msg, this.key, this.configuration).toString(); //U2FsdGVkX1+A5bM0Y4epRBtB/A2KYdupCgZXmSSu3hQ=
    console.log(encrypted);
    let decrypted = cryptoJS.AES.decrypt(encrypted, this.key, this.configuration).toString(cryptoJS.enc.Utf8);
    console.log(decrypted);*/

    return httpResponse;
  }

  get_website_data() {
    //var post= this.http.post(`${GlobalConstants.apiURL}get_website_data.php`, JSON.stringify(this.config));
    //const httpResponse = this.http.get(`${GlobalConstants.apiURL}get_reservation.php`);
    const httpResponse = this.http.get(`${GlobalConstants.apiURL}get_website_data.php`).pipe(
      /*tap(_ => 
        console.log('tap')
      ),*/
      map((res: any) => {

        let decrypted = cryptoJS.AES.decrypt(res, cryptoJS.enc.Utf8.parse(GlobalConstants.crypto.key), GlobalConstants.crypto.configuration).toString(cryptoJS.enc.Utf8);

        res= JSON.parse(decrypted);

        return res;
      }),
      catchError((res: HttpErrorResponse) => { // Si no responde
        /*console.log(JSON.stringify(res));
        this.router.navigate(['/']);*/

        return throwError(JSON.stringify(res));
      })
    );

    return httpResponse;
  }

  delete_user(user){ 
    var post=this.http.post(`${GlobalConstants.apiURL}delete_user.php`,JSON.stringify({user},this.config));
    return post
  }

}