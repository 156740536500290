import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { UserService } from '../../services/user.service';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GlobalConstants } from 'src/app/shared/constants/global-constants';

@Component({
  selector: 'app-sign-up',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.less']
})
export class SignUpComponent implements OnInit {

  user_type:string = GlobalConstants.user_type;
  user_id:number = GlobalConstants.user_id;
  
  userData;

  towers_array: any  = [];
  floors_array:  any = [];
  doors_array: any  = [];

  signupForm: FormGroup;

  response = [];

  alertshow:boolean = false;

  conf_delete_profile = false;

  constructor(private user: UserService, private formBuilder: FormBuilder, private route:ActivatedRoute,) {
    this.signupForm = this.formBuilder.group({
      'id' : new FormControl(0),
      'email' : new FormControl("", [Validators.required, Validators.email]),
      'tower' : new FormControl(undefined, Validators.required),
      'floor' : new FormControl(undefined, Validators.required),
      'door' : new FormControl(undefined, Validators.required),
      'password' : new FormControl("", [Validators.required, Validators.minLength(4)]),
      'repassword' : new FormControl("", [Validators.required, Validators.minLength(4)]),
      'name' : new FormControl("", [Validators.required, Validators.minLength(4)]),
      'code' : new FormControl(0)
      //'submit' : new FormControl({value: true }) //Chapuza: Prevent submit on window.open
    });
   }

  ngOnInit() {
    //this.user.currentUserData.subscribe(userData => this.userData = userData)

    if(this.route.snapshot.params.code){

      this.user.get_signup_data(this.route.snapshot.params.code).subscribe(
        data =>{
          console.log(data.user);
          this.signupForm.patchValue({
            email : data.user.email,
            id:  data.user.id,
            code:  this.route.snapshot.params.code
          });

          this.signupForm.value.id = data.user.id;

          this.towers_array = data['calendar_address_towers'];
          this.floors_array = data['calendar_address_floors'];
          this.doors_array = data['calendar_address_doors'];

          if( data.user.address != null){

            const address = data.user.address.match(/T(\d)F(\d)D(\d)/);

            this.signupForm.patchValue({
              tower: parseInt(address[1]),
              floor: parseInt(address[2]),
              door: parseInt(address[3]),
              name: data.user.nombre       
            });
          }

        }
      );
    
    }else if(this.user_id){

      const address = localStorage.getItem('address').match(/T(\d)F(\d)D(\d)/);

      this.signupForm.patchValue({
        email : localStorage.getItem('email'),
        id:  this.user_id,
        tower: parseInt(address[1]),
        floor: parseInt(address[2]),
        door: parseInt(address[3]),
        name: localStorage.getItem('nombre')       
      });

      this.user.get_website_data().subscribe(
        data =>{

          this.towers_array = data['calendar_address_towers'];
          this.floors_array = data['calendar_address_floors'];
          this.doors_array = data['calendar_address_doors'];

        }
      );
    }
  }

  alert_show(){
    this.alertshow = true;

    setTimeout(() => {
      this.alert_hide();
    }, 4000);
  }

  alert_hide(){
    this.alertshow = false;
  }

  /*signUp(data){
    
    //this.user.changeData(data);
  }*/

  submit(form: NgForm){

    if(form.value.id == 0 && form.value.email != "" && form.value.email !=" "){

      this.user.new_user(
        form.value
      ).subscribe(
        result => {
          if(result['resultado'] == 'OK') {
          this.response=result['mensaje'];
          
          this.alert_show();
        } 

        this.signupForm.patchValue({
          email : ''
        });       
        //this.send_mail(fecha_recor,hora,comentario);
      });

    }else if(form.dirty && form.valid && form.value.password ==  form.value.repassword && form.value.id > 0 && form.value.password.length >= 4){

      this.user.new_user_confirm(
        form.value
      ).subscribe(
        result => {
          if(result['resultado'] == 'OK') {
          this.response=result['mensaje'];
          
          this.alert_show();
        } 

        if(!this.user_id){ //Si nos estamos registrando por primera vez, si no, estariamos editando simplemente el perfil

          this.signupForm.patchValue({
            email : '',
            id: 0,
            code: 0
          });
        }

      });

    }
  }

  delete_profile(form: NgForm){

    this.user.delete_user(
      form.value
    ).subscribe(
      result => {
        if(result['resultado'] == 'OK') {
          this.response=result['mensaje'];
        
          this.alert_show();
        } 

        this.signupForm.patchValue({
          email : '',
          id: 0,
          code: 0,
          
        });
        
        this.user_id = 0;
        this.conf_delete_profile = false;
      //this.send_mail(fecha_recor,hora,comentario);
    });
  }
}