import { ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import { ReservationService } from '../../services/calendar.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';

import { ModalViewReservationComponent } from '../../../shared/components/modal_edit_reservation/modal_edit_reservation.component';

import { GlobalConstants } from 'src/app/shared/constants/global-constants';

declare var $: any; //JQUERY
//declare var NDEFReader: any;
/*declare global {
  interface Window {
    ndefreader: NDEFReader;
  }
}*/

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-nfc',
  templateUrl: './nfc.component.html',
  styleUrls: ['./nfc.component.less'],
})

export class NFCComponent implements OnInit {


  constructor() {
        
    /*if ("NDEFReader" in window) {
      alert(" ... Scan NDEF Tags ");
    }
    if ("NDEFWriter" in window) {
      alert(" ... Write NDEF Tags ");
    }*/

      const scriptElement = document.createElement('script');
      scriptElement.src = "/api/lib/nfc.js";//'https://www.paypalobjects.com/api/checkout.js';
      //scriptElement.onload = resolve;
      document.body.appendChild(scriptElement);
  }

  ngOnInit() {

  }

  //ndef = new NDEFReader();

 // w3cWebNfc = require("@types/w3c-web-nfc")

    //ReaderOpen example function
    ReaderOpen() {


      
     /* this.ndef.scan().then(() => {
  console.log("Scan started successfully.");
  this.ndef.onreadingerror = (event) => {
    console.log("Error! Cannot read data from the NFC tag. Try a different one?");
  };
  this.ndef.onreading = (event) => {
    console.log("NDEF message read.");
  };
}).catch(error => {
  console.log(`Error! Scan failed to start: ${error}.`);
});*/
    }

    

   
}