import { Component, OnInit, Injectable, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, FormControl  } from '@angular/forms';

import { ReservationService } from '../../../core/services/calendar.service';
import { GlobalConstants } from '../../constants/global-constants';
import { GlobalFunctions } from '../../constants/global-functions';

declare var $: any; //JQUERY
declare let paypal: any;

@Component({
  selector: 'app-modaleditreservation',
  templateUrl: './modal_edit_reservation.component.html',
  styleUrls: ['./modal_edit_reservation.component.less']
})
@Injectable({
  providedIn: 'root'
})
export class ModalViewReservationComponent implements OnInit {
  //[x: string]: any;

  //@Input() paypalForm: FormGroup;
  //@Input() public myParentData;
  //@Input('myParentData') public myNewVariable;  
  @Input() input_form_disabled; //null or true
  @Input() FormTitleString:string;
  @Input() fecha_create:string;
  @Input() fecha_edit:string;

  @Input() calendars_array:any = [];
  @Input() towers_array:any = [];
  @Input() floors_array:any = [];
  @Input() doors_array:any = [];

  @Input() date_selected:string;
  @Input() time_selected:string;
  @Input() zone_selected:number;
  @Input() zone_selected_str:string;
  @Input() season;

  @Output() filterArray_byInputs: EventEmitter<any> = new EventEmitter();

  user_logged:boolean = null;

  paypalForm: FormGroup;

  isCollapsed:boolean = false;

  customersSimilars_array: any = [];

      
  alertshow:boolean = false;

  response = [];

  user_type:string = GlobalConstants.user_type;
  user_id:number = GlobalConstants.user_id;

  arrayWords: any = [];

  //paymentoptions_array = [];

  paymentoptions_array = [
  
  [ //Winter
    [ //Pista de tenis 1
      {'value':'0','text':'Gratis', 'amount':0},
      //{'value':'1','text':'CON FOCOS', 'amount':1}
    ],
    [ //Pista de tenis 2
      {'value':'0','text':'Gratis', 'amount':0},
      //{'value':'1','text':'CON FOCOS', 'amount':1}
    ],
    [ //Pista de futbol
      {'value':'0','text':'Gratis', 'amount':0},
      //{'value':'1','text':'CON FOCOS', 'amount':1}
    ],
    [ //Barbacoa
      {'value':'0','text':'Gratis', 'amount':0},
      //{'value':'1','text':'CON FOCOS', 'amount':1}
    ],
    [ //Petanca 1
      {'value':'0','text':'Gratis', 'amount':0},
      //{'value':'1','text':'CON FOCOS', 'amount':1}
    ],
    [ //Petanca 2
      {'value':'0','text':'Gratis', 'amount':0},
      //{'value':'1','text':'CON FOCOS', 'amount':1}
    ],
    [ //Pergola
      {'value':'0','text':'Gratis', 'amount':0},
    ]
  ],

  [ //Summer
    [ //Pista de tenis 1
      {'value':'0','text':'COSTE ', 'amount':2},
      //{'value':'1','text':'CON FOCOS', 'amount':3}
    ],
    [ //Pista de tenis 2
      {'value':'0','text':'COSTE ', 'amount':2},
      //{'value':'1','text':'CON FOCOS', 'amount':3}
    ],
    [ //Pista de futbol
      {'value':'0','text':'DE DIA', 'amount':0},
      //{'value':'1','text':'CON FOCOS', 'amount':1}
    ],
    [ //Barbacoa
      {'value':'0','text':'Gratis', 'amount':0},
      //{'value':'1','text':'CON FOCOS', 'amount':1}
    ],
    [ //Petanca 1
      {'value':'0','text':'Gratis', 'amount':0},
      //{'value':'1','text':'CON FOCOS', 'amount':1}
    ],
    [ //Petanca 2
      {'value':'0','text':'Gratis', 'amount':0},
      //{'value':'1','text':'CON FOCOS', 'amount':1}
    ],
    [ //Pergola
      {'value':'0','text':'Gratis', 'amount':0},
    ]
  ]];

  /*towers_array = [
    {'index':'1','value':'1'},
    {'index':'2','value':'2'},
    {'index':'3','value':'3'},
    {'index':'4','value':'4'},
    {'index':'5','value':'5'},
    {'index':'6','value':'6'},
    {'index':'7','value':'7'},
    {'index':'8','value':'8'},
    {'index':'9','value':'9'},
  ];

  floors_array = [
    {'index':'1','value':'1'},
    {'index':'2','value':'2'},
    {'index':'3','value':'3'},
    {'index':'4','value':'4'},
    {'index':'5','value':'5'},
    {'index':'6','value':'6'},
    {'index':'7','value':'7'},
    {'index':'8','value':'8'},
  ];

  doors_array = [
    {'index':'A','value':'A'},
    {'index':'B','value':'B'},
    {'index':'C','value':'C'},
  ];*/
  
  conf_delete_reservation = false;

  //paypal : any = null; //Chapuza para poder acceder al objeto paypal desde el objeto paypalConfig

  constructor(private reservationService: ReservationService, private formBuilder: FormBuilder) {
    this.init_form();
  }

  ngOnInit() {    

    if(this.user_id > 0){
      const address = localStorage.getItem('address').match(/T(\d)F(\d)D(\d)/);

      this.paypalForm.patchValue({
        email : localStorage.getItem('email'),
        tower : parseInt(address[1]),
        floor : parseInt(address[2]),
        door : parseInt(address[3]),
        user : this.user_id
      });

      this.user_logged = true;
    }
  }

  alert_show(){
    this.alertshow = true;

    setTimeout(() => {
      this.alert_hide();
    }, 2000);
  }

  alert_hide(){
    this.alertshow = false;
  }

  init_form(){   
    
    this.paypalForm = this.formBuilder.group({
      'id' : new FormControl({value: '', disabled: true }),
      'status' : new FormControl({value: '', disabled: true }),
      'date_updated' : new FormControl({value: '', disabled: true }),
      //'nombre' : '', //new FormControl({value: '', disabled: input_form_disabled }, Validators.required),
      'email' : new FormControl("", [Validators.required, Validators.email]),
      'tower' : new FormControl(undefined, Validators.required),
      'floor' : new FormControl(undefined, Validators.required),
      'door' : new FormControl(undefined, Validators.required),
      'paymentoptions' : new FormControl({value: "" }),
      'user' : new FormControl(0 , Validators.required),
      //'pais_old' : new FormControl({value: '', disabled: true }), //Solución temporal mientras no se haya estandarizado
      'code' : new FormControl({value: '', disabled: true }),

      'submit' : new FormControl({value: true }) //Chapuza: Prevent submit on window.open
    });
  }

  show_modal(item: any, paypalForm?: FormGroup){ 
   
    if(paypalForm!=null && item!=null){

      const address = item.address.match(/T(\d)F(\d)D(\d)/);

      paypalForm.patchValue({
        email : item.email,
        tower : parseInt(address[1]),
        floor : parseInt(address[2]),
        door : parseInt(address[3]),
        paymentoptions : item.type.toString(),
      });

      paypalForm.value.id = item.id;
      paypalForm.value.status = item.status;
      paypalForm.value.date_updated = item.date_updated;
      paypalForm.value.code = item.code;

    }else{

      //paypalForm.reset();

      paypalForm.value.id = '';
      paypalForm.value.status = '';
      paypalForm.value.date_updated = '';
    }
      
    //this.newForm.get('destacado').enable();
    //this.view_register_note(idcompany);
  }

  submit(form: NgForm){

    this.checkout_ok(form);
  }

  get_NumberDayOfYear(stringdate){

    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();

    var date_sended = new Date(stringdate);
    var firts_day = new Date(currentYear+"-01-01");
    
    return Math.floor((Date.UTC(date_sended.getFullYear(), date_sended.getMonth(), date_sended.getDate()) - Date.UTC(firts_day.getFullYear(), firts_day.getMonth(), firts_day.getDate()) ) /(1000 * 60 * 60 * 24));  
  }

  checkout_ok(form){

    if(/*form.dirty &&*/ form.valid){

      form.value.price = this.paymentoptions_array[this.season][this.zone_selected][ this.paypalForm.value.paymentoptions ]["amount"];
      form.value.zone = this.zone_selected;
      form.value.type = this.paypalForm.value.paymentoptions;
      form.value.date = this.date_selected+' '+this.time_selected+":00:00";
      form.value.address = 'T'+form.value.tower+'F'+form.value.floor+'D'+form.value.door

      this.reservationService.new_reservation(
        form.value
      ).subscribe(
        result => {

          this.response=result['mensaje'];
          this.alert_show();

          if(result['resultado'] == 'OK') {
          
            //Here what to do after...
            this.calendars_array.push({
              date: form.value.date,
              zone: form.value.zone,
              comments: 'Su nueva reserva'
            });

            this.paypalForm.patchValue({
              email : '',
              tower : undefined,
              floor : undefined,
              door : undefined,
              paymentoptions: '',
              type : '' 
            });

            form.value.paypal = undefined;

            this.filterArray_byInputs.emit();

            $('#reservationModal').modal('hide');
          } 
      });
    }
  }

  //PAYPAL API: checkout.js
  //https://developer.paypal.com/demo/checkout-v4/
  public didPaypalScriptLoad: boolean = false;
  public loading: boolean = true;
  public paymentAmount: number = 1;
  public paypalConfig: any = {
    //sandox: sb-w73lg32193782@business.example.com m+)AJA1I
        //sb-rhaft32199539@personal.example.com i5=1yeUH
    env: this.user_type=="staff" || this.user_type=="admin" || this.user_type=="user" || this.user_type=="guest" ? 'production' : 'sandbox' ,//'sandbox',
    // Specify the style of the button
    style: {
      label: 'paypal',
      fundingicons: true, // optional Muestra la imagen de tarjeta de credito , no es compatible con sandbox
      branding: true, // optional Muestra la imagen de tarjeta de credito , no es compatible con sandbox
      size:  'medium',    // small | medium | large | responsive
      shape: 'rect',     // pill | rect
      color: 'blue',     // gold | blue | silver | black
      //tagline: false   //Tagline y fundingicons/branding no son compatibles
    },
    // Specify allowed and disallowed funding sources
    //
    // Options:
    // - paypal.FUNDING.CARD
    // - paypal.FUNDING.CREDIT
    // - paypal.FUNDING.ELV
    /*funding: {
      allowed: [ paypal.FUNDING.CARD, paypal.FUNDING.CREDIT ],
      disallowed: [ ]
    },*/
    client: {
      sandbox: 'Aa8O2momIjZY1duye6Kt381GE99wfxnaZ1CMTwyKEUXMrL8ZOMCp4BFKO5dCuPo1deQrtTh2_xg5nOv0',
      production: 'Abku_JlKVCMoUKFBtNp7l1EV9N54CsZPkGaRNe2YkC2-eMOfOQphA-YS_Tvj-nHX0Z7jl0lkvgCQ_p6Z'
    },
    commit: true,
    payment: (data, actions) => {

        return actions.payment.create({
          payment: {
            transactions: [
              { amount: { 
                total: /*this.paymentAmount/**/this.paymentoptions_array[this.season][this.zone_selected][ this.paypalForm.value.paymentoptions ]["amount"], 
                currency: 'EUR' } }
            ]
          },
          //experienceproficle: { noShipping: true }
          /*experience: {
            input_fields: {
                no_shipping: 1
            }
          }*/
        });

    },
    onAuthorize: (data, actions) => {

      var form = this.paypalForm;

        return actions.payment.execute().then((payment) => {
          // show success page
          /*
          console.log(payment);
{
    "id": "PAYID-M5DC3RA9VV47236VA325971Y",
    "intent": "sale",
    "state": "approved",
    "cart": "82624263HK556732S",
    "create_time": "2024-11-26T20:21:24Z",
    "payer": {
        "payment_method": "paypal",
        "status": "VERIFIED",
        "payer_info": {
            "email": "sb-rhaft32199539@personal.example.com",
            "first_name": "John",
            "middle_name": "John",
            "last_name": "Doe",
            "payer_id": "AEXWAQ7ZDNQ8G",
            "country_code": "ES",
            "shipping_address": {
                "recipient_name": "John Doe",
                "line1": "calle Vilamari 76993- 17469",
                "city": "Albacete",
                "state": "Albacete",
                "postal_code": "02001",
                "country_code": "ES"
            }
        }
    },
    "transactions": [
        {
            "amount": {
                "total": "1.00",
                "currency": "EUR",
                "details": {
                    "subtotal": "1.00",
                    "shipping": "0.00",
                    "handling_fee": "0.00",
                    "insurance": "0.00",
                    "shipping_discount": "0.00"
                }
            },
            "item_list": {},
            "related_resources": [
                {
                    "sale": {
                        "id": "2FG803773Y6357428",
                        "state": "completed",
                        "payment_mode": "INSTANT_TRANSFER",
                        "protection_eligibility": "ELIGIBLE",
                        "parent_payment": "PAYID-M5DC3RA9VV47236VA325971Y",
                        "create_time": "2024-11-26T20:21:33Z",
                        "update_time": "2024-11-26T20:21:33Z",
                        "amount": {
                            "total": "1.00",
                            "currency": "EUR",
                            "details": {
                                "subtotal": "1.00",
                                "shipping": "0.00",
                                "handling_fee": "0.00",
                                "insurance": "0.00",
                                "shipping_discount": "0.00"
                            }
                        }
                    }
                }
            ]
        }
    ]
}
          */

          form.value.paypal = payment;
          this.checkout_ok(form);
        });
  
    }
  };

  public ngAfterViewChecked(): void {
    if(!this.didPaypalScriptLoad) {
      this.loadPaypalScript().then(() => {
        //this.paypal = paypal; //Chapuza para poder acceder a la variable paypal desde el objeto paypalConfig
        paypal.Button.render(this.paypalConfig, '#paypal-button');
        this.loading = false;
      });
    }
  }

  public loadPaypalScript(): Promise<any> {
    
    if(!this.didPaypalScriptLoad) {
      this.didPaypalScriptLoad = true;
      return new Promise((resolve, reject) => {
        const scriptElement = document.createElement('script');
        scriptElement.src = "/api/lib/paypal/checkout.js";//'https://www.paypalobjects.com/api/checkout.js';
        scriptElement.onload = resolve;
        document.body.appendChild(scriptElement);
      });
    }
  }
  //PAYPAL API END

  delete_reservation(form){

    form.value.date = this.date_selected+' '+this.time_selected+":00:00";
    form.value.zone = this.zone_selected;

    this.reservationService.delete_reservation(
        form.value
      ).subscribe(
      data =>{
        this.response=data['mensaje'];
        this.alert_show();

        this.paypalForm.value.id = 0;

        setTimeout(() => {
          $('#reservationModal').modal('hide');
        }, 4000);
        
      }
    );
  }
}
