import { NgModule } from '@angular/core';
import { Routes, RouterModule} from '@angular/router';
import { NavbarComponent } from './core/components/navbar/navbar.component';
import { LoginComponent } from './core/pages/login/login.component';

import { AllReservationsComponent } from './core/pages/calendar_reservations/calendar_reservations.component'

import { SignUpComponent } from './core/pages/sign-up/sign-up.component';
import { NFCComponent } from './core/pages/nfc/nfc.component';

const routes: Routes = [
    {path: '', redirectTo: 'login', pathMatch: 'full'},
    {path: 'login', component: LoginComponent},
    {path: 'sign-up', component: SignUpComponent },
    {path: 'sign-up/:code', component: SignUpComponent },
    {path: 'recovery/:code', component: SignUpComponent },
    {path: 'profile', component: SignUpComponent },
    //{path: 'navbar', component: NavbarComponent},

    {path: 'reservation', component: AllReservationsComponent},
    {path: 'reservation/:idreservation', component: AllReservationsComponent},
    {path: 'reservation/zone/:idzone', component: AllReservationsComponent},
    {path: 'reservations', component: AllReservationsComponent},
    {path: 'invited/:guest', component: AllReservationsComponent},
    {path: 'nfc', component: NFCComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports:[RouterModule]
})

export class AppRoutingModule{

    ngOnInit() {
    }
}
