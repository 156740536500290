import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse,HttpHeaders  } from '@angular/common/http';
import { Observable } from 'rxjs';

import { GlobalConstants } from 'src/app/shared/constants/global-constants';

import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError } from 'rxjs/internal/observable/throwError';

import { map, tap } from 'rxjs/operators';

import * as cryptoJS from "crypto-js";

@Injectable({
  providedIn: 'root'
})

export class ReservationService {

  config;
  //fecha = new Date().toLocaleString('latin');

  constructor(public http: HttpClient) {
    
    this.config = {headers : {
      "Content-Type": "application/json; charset = utf-8;"
    }}; 
  }

  new_reservation(reservation){ 
    var post=this.http.post(`${GlobalConstants.apiURL}new_reservation.php`,JSON.stringify({reservation},this.config));
    return post
  }

  get_reservation(){

    const httpResponse = this.http.get(`${GlobalConstants.apiURL}get_calendar.php`);

    return httpResponse;
  }

  set_reservation(customer){
    var post=this.http.post(`${GlobalConstants.apiURL}set_reservation.php`,JSON.stringify({customer},this.config));
    return post;
  }

  get_calendar(filters){
    //var post = this.http.post(`${GlobalConstants.apiURL}get_reservation.php`,JSON.stringify(filters,this.config));   
    //const httpResponse = this.http.get(`${GlobalConstants.apiURL}get_reservation.php`);
    const httpResponse = this.http.get(`${GlobalConstants.apiURL}get_calendar.php`).pipe(
      /*tap(_ => 
        console.log('tap')
      ),*/
      map((res: any) => {

        let decrypted = cryptoJS.AES.decrypt(res, cryptoJS.enc.Utf8.parse(GlobalConstants.crypto.key), GlobalConstants.crypto.configuration).toString(cryptoJS.enc.Utf8);

        res= JSON.parse(decrypted);

        return res;
      }),
      catchError((res: HttpErrorResponse) => { // Si no responde
        /*console.log(JSON.stringify(res));
        this.router.navigate(['/']);*/

        return throwError(JSON.stringify(res));
      })
    );
    
    /*let encrypted = cryptoJS.AES.encrypt(this.msg, this.key, this.configuration).toString(); //U2FsdGVkX1+A5bM0Y4epRBtB/A2KYdupCgZXmSSu3hQ=
    console.log(encrypted);
    let decrypted = cryptoJS.AES.decrypt(encrypted, this.key, this.configuration).toString(cryptoJS.enc.Utf8);
    console.log(decrypted);*/
    
    return httpResponse;
  }

  delete_reservation(reservation){
    var post = this.http.post(`${GlobalConstants.apiURL}delete_reservation.php`,JSON.stringify({reservation},this.config));
    return post;
  }
}
