import { ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import { ReservationService } from '../../services/calendar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';

import { ModalViewReservationComponent } from '../../../shared/components/modal_edit_reservation/modal_edit_reservation.component';

import { GlobalConstants } from 'src/app/shared/constants/global-constants';

declare var $: any; //JQUERY

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import { DateAdapter } from '@angular/material/core';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-allreservations',
  templateUrl: './calendar_reservations.component.html',
  styleUrls: ['./calendar_reservations.component.less'],
})

export class AllReservationsComponent implements OnInit {

  //Para poder acceder alos elementos del hijo desde el padre:
  @ViewChild(ModalViewReservationComponent) childModalViewReservationComponent: ModalViewReservationComponent;

  subtitle_str: string = "";

  newForm: FormGroup;
  reservationForm: FormGroup;
  //paypalForm: FormGroup;

  FormTitleString: string;
  input_form_disabled: boolean = null;
  user_logged: boolean = null;
  fecha_create;
  fecha_edit;
  //customer : any = {"idempresa":0,"nombre":"","categoria":"","idcomercial":0,"date_remind":"","hora":"","comentario":"","destacado":0};
  //isCollapsed;
  date_selected:string = undefined;
  time_selected;
  zone_selected: number;

  message = "";
  
  response = "";
  alertshow:boolean = false;

  //createRemindForm: FormGroup;

  //idcommercial: number = 0; //Recibido en la URL

  user_type:string = GlobalConstants.user_type;
  user_id:number = GlobalConstants.user_id;

  nombre_comercial: string;
  email: string;

  zone_selected_str = "";
  season = 0;

  //today's date Date Material Angular Picker
  todayDate:Date = new Date();
  yesterdayDate = new Date();
  maxDate = new Date();

  dataPickerDatesDisbled = [ //Podemos descativar algunos dias si queremos
    new Date("8/14/2024")
  ];
  dataPickerDatesFilterDisabled = (d: Date): boolean => {
    const time=d.getTime();
    return !this.dataPickerDatesDisbled.find(x=>x.getTime()==time);
  }

  calendars_array: any = [];
  towers_array: any  = [];
  floors_array:  any = [];
  doors_array: any  = [];

  filter:number;
  calendars_array_filtered:any = [];
  calendars_array_user:any = []; //Las reservas del usuario

  /*hours: any = [
    {value: '9', viewValue: '09:00 H'},
    {value: '10', viewValue: '10:00 H'},
    {value: '11', viewValue: '11:00 H'},
    {value: '12', viewValue: '12:00 H'},
    {value: '13', viewValue: '13:00 H'},
    {value: '14', viewValue: '14:00 H'},
    {value: '15', viewValue: '15:00 H'},
    {value: '16', viewValue: '16:00 H'},
    {value: '17', viewValue: '17:00 H'},
    {value: '18', viewValue: '18:00 H'},
    {value: '19', viewValue: '19:00 H'},
    {value: '20', viewValue: '20:00 H'},
    {value: '21', viewValue: '21:00 H'}
  ];

  zones: any = [
    {value: 0 , viewValue: 'TENIS PISTA 1'},
    {value: 1 , viewValue: 'TENIS PISTA 2'},
    {value: 2 , viewValue: 'FUTBOL (POLIDEPORTIVO)'},
    {value: 3 , viewValue: 'BARBACOA (POLIDEPORTIVO)'},
    {value: 4 , viewValue: 'PETANCA PISTA 1'},
    {value: 5 , viewValue: 'PETANCA PISTA 2'},
    {value: 6 , viewValue: 'MERENDERO (PERGOLA)'},
  ];*/

  hours = new Array;
  zones = new Array;

  status_array_str = GlobalConstants.status_array_str;
  destacado_array_str = GlobalConstants.destacado_array_str;
  procedencia_array_str = GlobalConstants.procedencia_array_str;
  comercials_array_str = GlobalConstants.comercials_array_str;
  sectors_array_str = GlobalConstants.sectors_array_str;
  
  key: string = 'nombre';
  reverse: boolean = false;

  sort(key){
    this.key = key;
    this.reverse = !this.reverse;
  }
  p: number = 1;

  ShowLinkCol: boolean = false;
  ShowCommentCol: boolean = false;
  ShowAddressCol: boolean = false;

  searchText: string = '';
  filterMetadata = { 
    count: 0,
    filters: [""]
   };

  constructor(private reservationService: ReservationService,private route:ActivatedRoute, private router:Router, private viewreservationService:ModalViewReservationComponent, private formBuilder: FormBuilder, private matDatepicker: MatDatepickerModule, private dateAdapter: DateAdapter<Date>) { 
    //Conectamos con los formularios de otros componentes
    //this.reservationForm = viewcustomerService.newForm;
    //this.createRemindForm = createremindService.createRemindForm;

    this.newForm = new FormGroup({
      date_selected: new FormControl(),
      time_selected: new FormControl(),
      zone_selected: new FormControl(),
   });

    this.dateAdapter.getFirstDayOfWeek = () => 1;//Lunes primer dia de la semana
    this.yesterdayDate.setDate(this.yesterdayDate.getDate() - 1);
    this.maxDate.setDate(this.maxDate.getDate() + 7);

    this.reservationForm = new FormGroup({
      code: new FormControl(),
      email: new FormControl(),
   });
  }

  ngOnInit() {

    //this.idcommercial=this.route.snapshot.params.idcomercial;
    //this.nombre_comercial=this.route.snapshot.params.nombre_comercial;
    
    //this.email=localStorage.getItem('email');

    //this.filter=this.route.snapshot.params.filter;

    if( this.route.snapshot.params.guest != null ){
      localStorage.setItem('nombre', "INVITADO");
      localStorage.setItem('email', "");
      localStorage.setItem('tipo', "guest");
      localStorage.setItem('id', "0");

      GlobalConstants.user_type = "guest";
      GlobalConstants.user_id = 0;
    }

    //if( this.route.snapshot.params.guest != null && this.user_type != undefined && ( this.user_type.indexOf('admin') > -1 || this.user_type.indexOf('staff') || this.user_type.indexOf('user') ) ){

      this.reservationService.get_calendar({/*idcomercial : this.idcommercial, */ filter: this.filter }).subscribe(
        data =>{

          if( localStorage.getItem('tipo') == "demo" ){

            var nextday = new Date();
            var data_demo = new Array();

            for(let i=0 ; i<6; i++){

              nextday.setDate(nextday.getDate() + 1);

              data_demo[i] = {
                "addess":"U1T33B",
                "id": i,
                "user": 0,
                "email": "email",
                "date": this.getDate2String(nextday)+" "+(9+i)+":00:00",
                "status": "1",
                "price": "2.00",
                "comments": "DEMO EXAMPLE "+i,
                "date_created": "2024-10-28 19:09:01",
                "date_updated": "2024-10-28 19:09:01",
                "index": 0,
                "type": 1,
                "zone": i
              }
            }

            data['calendar_reservations'] = data_demo.concat(data['calendar_reservations']);
          }

          this.hours = data['calendar_hours'];
          this.zones = data['calendar_zones'];
          this.towers_array = data['calendar_address_towers'];
          this.floors_array = data['calendar_address_floors'];
          this.doors_array = data['calendar_address_doors'];

          this.init_table_reservations(data['calendar_reservations']);

          if(this.route.snapshot.params.idreservation){ //Abrimos directamente un ID

            this.show_reservation( this.route.snapshot.params.idreservation );

          }else if(this.router.url == "/reservation" ){
            this.input_form_disabled = null;

            $('#checkreservationViewModal').modal('show');

          }else if(this.route.snapshot.params.idzone){

            this.zone_selected = this.route.snapshot.params.idzone;
          }

          if(this.user_id){

            this.calendars_array_user = this.filterArray_byuser( this.calendars_array , this.user_id);
            if(this.user_id) this.email = localStorage.getItem('email');
          }

          this.filterArray_byInputs(this.calendars_array);

        }
      );

    /*}
    else{

      localStorage.setItem('nombre', "DEMO");
      localStorage.setItem('email', "");
      localStorage.setItem('tipo', "GUEST");
      localStorage.setItem('id', "0");    
    }*/

  }

  init_table_reservations(data){

    if(data == "logout") window.location.href = '/#/login';
    else{

      this.calendars_array= data;

      for (let i in this.calendars_array){
        this.calendars_array[i].index = i;
        this.calendars_array[i].comments = this.zones[ this.calendars_array[i].zone ].time + " Hora(s)";
        /*if(this.customers_array[i].nombre == null) this.customers_array[i].nombre = ''; //Fix problem order table by nombre
        this.customers_array[i].idprocedencia_str = this.procedencia_array_str[ this.customers_array[i].idprocedencia ].value;
        this.customers_array[i].status_str = this.status_array_str[ this.customers_array[i].status ].value;
        this.customers_array[i].destacado_str = this.destacado_array_str[ this.customers_array[i].destacado ].value;
        if(this.customers_array[i].sector != null) this.customers_array[i].sector_str = this.sectors_array_str[ this.customers_array[i].sector ].value;
        else this.customers_array[i].sector_str = '';*/

        //this.customers_array[i].comercial_str = GlobalConstants.comercials_array_str[ GlobalConstants.comercials_array_str.findIndex(element => element.index === this.customers_array[i].idcomercial) ].value; //Para mostrar el comercial en la tabla de empresas similares del modal. TODO Ralentiza mucho
      }

      this.calendars_array_filtered =  this.filterArray( this.calendars_array );
    }
  }
  
  print(){
    window.print();
  }

  get_season(){
    if( this.get_NumberDayOfYear( this.date_selected ) >= 182 && this.get_NumberDayOfYear( this.date_selected ) <= 243 ){
      this.season = 1;
    }else{
      this.season = 0;
    }
  }

  show_modal_new(){

    this.FormTitleString = "New ";
    this.fecha_create = this.fecha_edit = '';

    this.get_season();

    //let item = {season: this.season};
    //this.customer = item;
    const item = null;

    this.input_form_disabled = null;

    this.viewreservationService.show_modal(item, this.childModalViewReservationComponent.paypalForm);
  }

  show_modal_view(reservation: any){

    this.input_form_disabled = false;

    this.date_selected = formatDate(new Date(reservation.date), 'yyyy-MM-dd', 'en-US');
    this.zone_selected = reservation.zone;
    this.time_selected = formatDate(new Date(reservation.date), 'hh:mm', 'en-US');
    this.get_season();
    this.viewreservationService.show_modal(reservation, this.childModalViewReservationComponent.paypalForm);
  }

  get_NumberDayOfYear(stringdate){

    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();

    var date_sended = new Date(stringdate);
    var firts_day = new Date(currentYear+"-01-01");
    
    return Math.floor((Date.UTC(date_sended.getFullYear(), date_sended.getMonth(), date_sended.getDate()) - Date.UTC(firts_day.getFullYear(), firts_day.getMonth(), firts_day.getDate()) ) /(1000 * 60 * 60 * 24));  
  }

  filterArray(arraySearch){

    let arrayResults = [];

      arrayResults = arraySearch.filter( obj => {

        return this.get_NumberDayOfYear(obj.date) > this.get_NumberDayOfYear(this.todayDate) && obj.status== 1 ?  true : false;
      });

    return arrayResults;
  }

  filterArray_byInputs(arraySearch = this.calendars_array){
    this.calendars_array_filtered = this.filterArray_byZone(this.filterArray_byTime( this.filterArray_byDate(arraySearch) ) );
  }

  filterArray_byDate(arraySearch){

    let arrayResults = [];

    var currentDate = new Date();

      arrayResults = arraySearch.filter( obj => {
          const dateObj = new Date(obj.date);

          return this.date_selected == null || this.date_selected == "" ?  dateObj >= currentDate : this.getDate2String(dateObj) == this.date_selected;
      });

    return arrayResults;
  }

  filterArray_byTime(arraySearch){

    let arrayResults = [];

      arrayResults = arraySearch.filter( obj => {
        const dateObj = new Date(obj.date);
        const endTime = dateObj.getHours() + this.zones[ obj.zone ].time ; //Las reservas pueden ser por duracion distinta

        return this.time_selected == null || this.time_selected == ""  ?  true : ( dateObj.getHours().toString() == this.time_selected || (this.time_selected >= dateObj.getHours() && this.time_selected <= endTime ) ) ;
      });

    return arrayResults;
  }

  filterArray_byZone(arraySearch){

    let arrayResults = [];

      arrayResults = arraySearch.filter( obj => {
        return this.zone_selected == undefined ?  true : obj.zone == this.zone_selected;
      });

    return arrayResults;
  }

  getDate2String(dateObj , divider='-'){
    return dateObj.getFullYear()+divider+(dateObj.getMonth()<=8?'0':'')+(dateObj.getMonth()+1)+divider+(dateObj.getDate()<=9?'0':'')+dateObj.getDate()
  }

  //events: string[] = [];
  onEventChangedSelectionDay(type: string, event: MatDatepickerInputEvent<Date>) {
    //this.events.push(`${type}: ${event.value}`);

    if(event.value != null){
      //console.log(`${event.value.getUTCMonth()}`);
      this.date_selected = this.getDate2String(event.value);
      //this.date_selected = this.searchText;

      this.filterArray_byInputs(this.calendars_array);
    }

    this.warningMessages();
  }

  onEventChangedSelectionTime(event){

    this.time_selected = event.value;
    this.filterArray_byInputs(this.calendars_array);

    this.warningMessages();
  }

  onEventChangedSelectionZone(event){

    this.zone_selected = event.value;
    this.zone_selected_str = this.zones[this.zone_selected]['text'] + ' (' + this.zones[this.zone_selected]['time'] + ' hora/s)';

    this.filterArray_byInputs(this.calendars_array);
  }

  warningMessages(){

    if(this.date_selected == this.getDate2String(this.todayDate) ){
      this.message = "La hora mínima de reserva de hoy es a las "+ formatDate(this.todayDate, 'HH:00', 'en-US');

      if(formatDate(this.todayDate, 'HH', 'en-US')  >=  this.time_selected){
        this.input_form_disabled = true;
      }else{
        this.input_form_disabled = null;
      }        
    }
    else{
      this.input_form_disabled = null;
      this.message = "";
    } 
  }

  resetForm(){

    this.newForm.patchValue({
      date_selected: undefined
    });

    this.date_selected = undefined;
    this.time_selected = undefined;
    this.zone_selected = undefined;

    //this.zone_selected_str = "";

    this.filterArray_byInputs(this.calendars_array);
  }

  submit(form: NgForm){

    const arrayResults = this.calendars_array.filter( obj => {

      return form.value.code == obj.code && form.value.email == obj.email ?  true : false;
    });

    if(arrayResults.length == 1){
      this.show_modal_view( arrayResults[0] );
      $('#reservationModal').modal('show');
    }else{
      this.response= "No se ha encontrado ningún resultado";
      this.alert_show();
    }
  }

  alert_show(){
    this.alertshow = true;

    setTimeout(() => {
      this.alert_hide();
    }, 2000);
  }

  alert_hide(){
    this.alertshow = false;
  }

  filterArray_byuser(arraySearch, user){

    let arrayResults = [];

      arrayResults = arraySearch.filter( obj => {

        return obj.user== user ?  true : false;
      });

    return arrayResults;
  }
  
  show_reservation(code, email = null){
    /*const index = this.customers_array.findIndex(element => element.idempresa === this.route.snapshot.params.idempresa);
    const item = this.customers_array[index];

    this.show_modal_view(item);*/

    this.reservationForm.patchValue({
      code : code,
      email : email
    });

    this.input_form_disabled = true;

    $('#checkreservationViewModal').modal('show');
    
  }
}